<template>
    <div>
        <!-- 表格 -->
        <el-table :data="tableData" style="width: 100% ">
            <el-table-column prop="code" label="人员编号"></el-table-column>
            <el-table-column prop="name" label="名称"></el-table-column>
            <el-table-column prop="time" label="时间"></el-table-column>
            <el-table-column prop="force" label="力量"></el-table-column>
            <el-table-column prop="test_time" label="测试时间"></el-table-column>
            <el-table-column prop="create_time" label="数据上传时间"></el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination
            :current-page.sync="form.page"
            :page-size="form.page_siaze"
            :total="form.total"
            @current-change="handleCurrentChange"
            class="table-pagination"
            layout="total, jumper,  ->, prev, pager, next"
        ></el-pagination>
    </div>
</template>

<script>
export default {
    data() {
        return {
            tableData: [],
            form: {
                tableList: [],
                page: 1, //当前页
                page_size: 10,
                total: 0,
            },
            
        };
    },

    mounted() {
        this.getData()
    },
    
    methods: {
        // 分页
        handleCurrentChange(val) {
            this.form.page = val;
            this.getData();
        },
        getData() {
            let data = {
                page: this.form.page,
                page_size: this.form.page_size,
                type: 1,
            };
            this.$axios
                .post("/p/device/lists", this.$qs(data))
                .then((res) => {
                    if (res.data.code === 0) {
                        this.tableData = res.data.data.rows
                        this.form.total = res.data.data.total;
                    } else {
                        this.$utils.message({
                            type: "error",
                            message: res.message,
                        });
                    }
                })
        }

    },
};
</script>

<style lang="scss" scoped>
</style>
